import { __assign, __awaiter, __generator } from "tslib";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { scrollUpToClass } from "@/core/helpers/custom";
import { debounce } from "lodash";
export default defineComponent({
    name: "KTFooter",
    setup: function () {
        var _this = this;
        var router = useRouter();
        var store = useStore();
        var route = useRoute();
        var i18n = useI18n();
        var t = useI18n().t;
        var isUserAuthenticated = computed(function () {
            return store.getters.isUserAuthenticated;
        });
        var btnFooter = computed(function () {
            return [
                {
                    iconName: "bi bi-house",
                    text: "Home",
                    routeName: "home",
                    event: "scrollUp",
                    show: true,
                },
                {
                    iconName: "bi bi-shop",
                    text: "Store",
                    routeName: "store",
                    show: true,
                },
                {
                    iconName: "bi bi-heart",
                    text: "Wishlist",
                    routeName: "account-wishlist",
                    show: isUserAuthenticated.value,
                },
            ].filter(function (element) {
                return element.show;
            });
        });
        var btnFooterEdit = computed(function () {
            return [
                {
                    iconName: "bi bi-house",
                    text: "Home",
                    routeName: "edit-home",
                    event: "scrollUp",
                    show: true,
                },
                {
                    iconName: "bi bi-shop",
                    text: "Store",
                    routeName: "store",
                    show: true,
                },
                {
                    iconName: "bi bi-heart",
                    text: "Wishlist",
                    routeName: "account-wishlist",
                    show: isUserAuthenticated.value,
                },
            ].filter(function (element) {
                return element.show;
            });
        });
        var adminToken = store.getters.adminToken;
        var btnData = adminToken ? btnFooterEdit : btnFooter;
        var accountLink = computed(function () {
            return [
                {
                    iconName: "bi bi-geo-alt",
                    text: "Address",
                    routeName: "account-address-listing",
                    show: isUserAuthenticated.value,
                },
                {
                    iconName: "bi bi-receipt",
                    text: "Order",
                    routeName: "account-order-listing",
                    show: isUserAuthenticated.value,
                },
                {
                    iconName: "bi bi-person-circle",
                    text: "Account",
                    routeName: "account-settings",
                    show: isUserAuthenticated.value,
                },
            ].filter(function (element) {
                return element.show;
            });
        });
        var countries = computed(function () {
            return {
                en: {
                    flag: require("@/assets/svg/united-states.svg"),
                    name: "English",
                    lang: "en",
                },
                km: {
                    flag: require("@/assets/svg/cambodia.svg"),
                    name: "Khmer",
                    lang: "km",
                },
            };
        });
        var signOut = function () {
            Swal.fire({
                title: t("Are you sure you want to log out?"),
                showCancelButton: true,
                confirmButtonText: t("Yes"),
                cancelButtonText: t("Cancel"),
                confirmButtonColor: "#00AF27",
            }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var showNotification;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 2];
                            showNotification = true;
                            return [4 /*yield*/, store.dispatch(Actions.LOGOUT, showNotification).then(function () {
                                    router.push({ name: "mobile-login" });
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        };
        var setLang = function (lang) {
            localStorage.setItem("lang", lang);
            i18n.locale.value = lang;
        };
        var dynamicFooterEvent = function (methodName) {
            if (methodName === "scrollUp") {
                scrollUpToClass("card_div");
            }
        };
        var edit = ref(route.query.edit === "true");
        var handleEdit = debounce(function () {
            edit.value = !edit.value;
            var query = __assign({}, router.currentRoute.value.query);
            if (!edit.value) {
                delete query.edit;
            }
            else {
                query.edit = String(edit.value);
            }
            router.push({ name: "edit-home", query: query });
        }, 100);
        return {
            btnFooter: btnFooter,
            signOut: signOut,
            route: route,
            isUserAuthenticated: isUserAuthenticated,
            accountLink: accountLink,
            i18n: i18n,
            setLang: setLang,
            countries: countries,
            dynamicFooterEvent: dynamicFooterEvent,
            btnData: btnData,
            adminToken: adminToken,
            handleEdit: handleEdit,
        };
    },
});
